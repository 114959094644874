<template>
    <FormKit
        id="signup-form"
        type="form"
        v-model="selectedValues"
        :actions="false"
        :config="config"
        #default="context"
    >
        <!-- Subscription Information section -->
        <FormSectionHeader
            class="pb-4"
            title="Subscription Information"
        />
        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-3 max-w-2xl">
            <div class="w-full">
                <FormKit
                    type="text"
                    label="Organization Name"
                    name="institution"
                    validation="required:trim"
                    @keydown.enter.prevent.stop="
                        focusFormInput($event, 'accountLevel')
                    "
                />
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-3">
                <div
                    class="w-full"
                    :class="{ 'col-span-2': isAccountLevelEmpty }"
                >
                    <FormKit
                        type="select"
                        label="Subscription Level"
                        name="accountLevel"
                        :options="options.account_level"
                        validation="required:trim"
                        @keydown.enter.prevent.stop="
                            focusFormInput(
                                $event,
                                isAccountLevelEmpty
                                    ? 'country'
                                    : 'subscriptionLength'
                            )
                        "
                    />
                </div>
                <div
                    class="w-full"
                    v-if="!isAccountLevelEmpty"
                >
                    <FormKit
                        v-if="isSubscriptionLengthLocked"
                        type="select"
                        label="Subscription Length"
                        name="subscriptionLength"
                        :options="[
                            {
                                label: '3 Months',
                                value: '0.3',
                                attrs: { disabled: true },
                            },
                        ]"
                        validation="required:trim"
                        :value="'0.3'"
                        :ignore="true"
                        :readonly="true"
                        @keydown.enter.prevent.stop="
                            focusFormInput($event, 'country')
                        "
                    />
                    <FormKit
                        v-else
                        type="select"
                        label="Subscription Length"
                        name="subscriptionLength"
                        :options="options.subscription_length"
                        validation="required:trim"
                        :preserve="true"
                        @keydown.enter.prevent.stop="
                            focusFormInput($event, 'country')
                        "
                    />
                </div>
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-3 max-w-2xl">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-3">
                <div
                    class="w-full"
                    :class="{ 'col-span-2': !isRegionInputVisible }"
                >
                    <FormKit
                        type="select"
                        label="Country"
                        name="country"
                        :options="options.countries"
                        placeholder="Select Country"
                        validation="required:trim"
                        :preserve="true"
                        @keydown.enter.prevent.stop="
                            focusFormInput(
                                $event,
                                isRegionInputVisible ? 'state' : 'city'
                            )
                        "
                    />
                </div>
                <div
                    class="w-full"
                    v-if="isRegionInputVisible"
                >
                    <FormKit
                        v-if="isStateInputVisible"
                        type="select"
                        label="State"
                        name="state"
                        :options="options.states"
                        placeholder="Select State"
                        validation="required:trim"
                        @keydown.enter.prevent.stop="
                            focusFormInput($event, 'city')
                        "
                    />
                    <FormKit
                        v-if="isProvinceInputVisible"
                        type="select"
                        label="Province"
                        name="state"
                        :options="options.provinces"
                        placeholder="Select Province"
                        validation="required:trim"
                        @keydown.enter.prevent.stop="
                            focusFormInput($event, 'city')
                        "
                    />
                </div>
            </div>
            <div class="w-full">
                <FormKit
                    type="text"
                    label="City"
                    name="city"
                    validation="required:trim"
                    :disabled="isCityInputDisabled"
                    @keydown.enter.prevent.stop="
                        focusFormInput($event, 'firstName')
                    "
                />
            </div>
        </div>
        <!-- Subsciption Contact section -->
        <FormSectionHeader
            class="pb-4 pt-4"
            title="Subsciption Contact"
        />
        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-3 max-w-2xl">
            <div class="w-full">
                <FormKit
                    type="text"
                    label="First Name"
                    name="firstName"
                    validation="required:trim"
                    @keydown.enter.prevent.stop="
                        focusFormInput($event, 'lastName')
                    "
                />
            </div>
            <div class="w-full">
                <FormKit
                    type="text"
                    label="Last Name"
                    name="lastName"
                    validation="required:trim"
                    @keydown.enter.prevent.stop="
                        focusFormInput($event, 'emailAddress')
                    "
                />
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-3 max-w-2xl">
            <div class="w-full">
                <FormKit
                    type="text"
                    label="Primary Contact Email"
                    name="emailAddress"
                    validation="required:trim|email"
                    @keydown.enter.prevent.stop="
                        focusFormInput($event, 'username')
                    "
                />
                <div
                    class="text-red-500 mb-1 text-xs"
                    v-if="hasEmailError"
                >
                    The email entered must be unique.
                </div>
            </div>
            <div class="w-full">
                <FormKit
                    type="text"
                    label="Billing Contact Email"
                    name="billingEmailAddress"
                    validation="required:trim|email"
                    @keydown.enter.prevent.stop="
                        focusFormInput($event, 'username')
                    "
                />
                <div
                    class="text-red-500 mb-1 text-xs"
                    v-if="hasEmailError"
                >
                    The email entered must be unique.
                </div>
            </div>
            <div class="w-full">
                <FormKit
                    type="text"
                    label="Username"
                    name="username"
                    validation="required:trim"
                    autocomplete="new-password"
                    aria-autocomplete="new-password"
                    @keydown.enter.prevent.stop="
                        focusFormInput($event, 'password')
                    "
                />
                <div
                    class="text-red-500 mb-1 text-xs"
                    v-if="hasUsernameError"
                >
                    The username entered must be unique.
                </div>
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-3 max-w-2xl">
            <div class="w-full">
                <FormKit
                    type="password"
                    label="Password"
                    name="password"
                    autocomplete="new-password"
                    aria-autocomplete="new-password"
                    validation="required:trim|length:8"
                    @keydown.enter.prevent.stop="
                        focusFormInput($event, 'password_confirmation')
                    "
                />
            </div>
            <div class="w-full">
                <FormKit
                    type="password"
                    label="Re-enter Password"
                    name="password_confirmation"
                    validation="required:trim|confirm:password"
                    validation-label="Password"
                    @keydown.enter.prevent.stop="onFormSubmit($event, context)"
                />
            </div>
        </div>
        <div class="mb-5">
            <div class="flex items-start">
                <FormKit
                    type="checkbox"
                    name="terms"
                    outer-class="$reset outer p-0"
                    wrapper-class="$reset wrapper flex flex-row items-center h-5 space-x-1"
                    inner-class="$reset inner block h-5 my-1"
                    input-class="$reset checkbox block h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                    label-class="$reset label block h-5 my-1 text-xs text-gray-900"
                    validation="accepted"
                    validation-visibility="dirty"
                    :validation-messages="{
                        accepted:
                            'Accepting Terms and Conditions of Use is required to create an account.',
                    }"
                    @keyup.enter.stop="
                        /** @type {HTMLElement} */ ($event?.target)?.click()
                    "
                >
                    <template #label>
                        <span class="label block ml-1 text-xs text-gray-900">
                            I agree to the
                            <a
                                :href="termsLink"
                                class="underline hover:text-gray-400"
                                target="_blank"
                            >
                                Terms and Conditions of Use
                            </a>
                        </span>
                    </template>
                </FormKit>
            </div>
        </div>
        <div>
            <VariantButton
                name="signup"
                variant="login"
                label="Subscribe"
                @click="onFormSubmit($event, context)"
            />
        </div>
    </FormKit>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed, reactive, ref, onMounted } from 'vue';
    import { useRoute, useRouter } from 'vue-router';

    // <!-- ENUMS -->
    import { CountryName } from '@/enums';

    // <!-- COMPONENTS -->
    import VariantButton from '@/components/buttons/VariantButton.vue';
    import FormSectionHeader from '@/components/forms/partials/FormSectionHeader.vue';

    // <!-- COMPOSABLES -->
    import { useAxios } from '@/plugins/axios';
    import { useMarketingDomain } from '@/hooks/env/useMarketingDomain';
    import { states, provinces } from '@/hooks/useStates';
    import { countries } from '@/hooks/useCountries';

    // <!-- CONSTANTS -->

    /** Configured Axios instance. */
    const axios = () => useAxios();

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SignUpFields',
        components: {
            // FormSubmitCancel,
            VariantButton,
            FormSectionHeader,
        },
        props: {},
        setup(props, context) {
            // ==== COMPOSABLES ====
            const router = useRouter();
            const { getMarketingLink } = useMarketingDomain();

            // ==== CONSTANTS ====
            let options = {
                account_level: [
                    {
                        label: 'Select Level',
                        value: '',
                        attrs: { disabled: true },
                    },
                    { label: 'Free Trial', value: '5' },
                    { label: 'Basic', value: '6' },
                    { label: 'Basic Plus', value: '9' },
                    { label: 'Professional', value: '7' },
                    { label: 'Professional Plus', value: '8' },
                    {
                        label: 'Enterprise',
                        //TODO: Add Enterprise level value.
                        //value: '6'
                    },
                ],
                subscription_length: [
                    {
                        label: 'Select Length',
                        value: '',
                        attrs: { disabled: true },
                    },
                    { label: '1 year', value: '1' },
                    { label: '2 years', value: '2' },
                    { label: '3 years', value: '3' },
                    { label: '4 years', value: '4' },
                    { label: '5 years', value: '5' },
                ],
                countries: [
                    {
                        label: 'Select Country',
                        value: '',
                        attrs: { disabled: true },
                    },
                    ...countries.map((c) => ({ label: c, value: c })),
                ],
                states: [
                    {
                        label: 'Select State',
                        value: '',
                        attrs: { disabled: true },
                    },
                    // TODO: Map states to appropriate abbreviations.
                    ...states.map((s) => ({ label: s, value: s })),
                ],
                provinces: [
                    {
                        label: 'Select Province',
                        value: '',
                        attrs: { disabled: true },
                    },
                    // TODO: Map provinces to appropriate abbreviations.
                    ...provinces.map((p) => ({ label: p, value: p })),
                ],
            };

            // ==== STATE ====
            const termsLink = ref('');
            let hasUsernameError = ref('');
            let hasEmailError = ref('');
            let signupErrors = ref([]);

            let accountQuery = ref(useRoute().query);
            let selectedValues = reactive({
                accountLevel: '',
                subscriptionLength: '',
                institution: '',
                /** @type {Country.Name | ''} */
                country: CountryName.ByISO3['USA'],
                state: '',
                city: '',
                firstName: '',
                lastName: '',
                emailAddress: '',
                billingEmailAddress: '',
                username: '',
                password: '',
                password_confirmation: '',
                terms: false,
            });

            const config = reactive({
                validationVisibility: 'blur',
            });

            // ==== PROPERTIES ====
            const isAccountLevelEmpty = computed(() => {
                const currentAccountLevel = selectedValues.accountLevel;
                return (
                    currentAccountLevel === '' || currentAccountLevel === null
                );
            });

            const isSubscriptionLengthLocked = computed(() => {
                const currentAccountLevel = selectedValues.accountLevel;
                return currentAccountLevel === '5';
            });

            const isCityInputDisabled = computed(() => {
                return (
                    selectedValues.country === '' ||
                    selectedValues.country === null
                );
            });

            const isStateInputVisible = computed(() => {
                return selectedValues.country === CountryName.ByISO3.USA;
            });

            const isProvinceInputVisible = computed(() => {
                return selectedValues.country === CountryName.ByISO3.CAN;
            });

            const isRegionInputVisible = computed(() => {
                return (
                    isStateInputVisible.value || isProvinceInputVisible.value
                );
            });

            // ==== METHODS ====
            /**
             *
             * @param {MouseEvent} e
             * @param {import('@formkit/core').FormKitFrameworkContext} context
             */
            const onFormSubmit = (e, context) => {
                config.validationVisibility = 'live';
                if (context.state.valid) {
                    createAccount();
                } else {
                    console.error('Form is not valid.');
                }
            };

            const createAccount = async () => {
                try {
                    hasUsernameError.value = '';
                    hasEmailError.value = '';
                    signupErrors.value = [];
                    const response = await axios().post(`/register`, {
                        account_level: selectedValues.accountLevel, // Valid 5,6,7,8,9
                        account_subscription_length:
                            isSubscriptionLengthLocked.value
                                ? '1' // BUG: FREE TRIAL defaults value to 1, since fractional value is not allowed.
                                : selectedValues.subscriptionLength,
                        account_name:
                            selectedValues.firstName +
                            ' ' +
                            selectedValues.lastName,
                        account_city: selectedValues.city,
                        account_state:
                            // BUG: Backend enforces a 2-character limit on states.
                            selectedValues.state && selectedValues.state !== ''
                                ? selectedValues.state.slice(0, 2)
                                : 'NA',
                        account_country: selectedValues.country,
                        first_name: selectedValues.firstName,
                        last_name: selectedValues.lastName,
                        user_name: selectedValues.username, // Must be unique.
                        email: selectedValues.emailAddress, // example@sharpnotions.com
                        billingEmail: selectedValues.billingEmailAddress,
                        password: selectedValues.password,
                        password_confirmation:
                            selectedValues.password_confirmation,
                    });

                    if (selectedValues.accountLevel === '5') {
                        // Redirect to the Analysis page.
                        router.push('/analysis');
                    } else {
                        window.location.replace(
                            `https://store.imagepermanenceinstitute.org/cart/add/e-${response.data.account.plan_code}_q${response.data.account.subscription_length}_z${response.data.account.id}?destination=cart`
                        );
                    }
                } catch (error) {
                    signupErrors.value.push(
                        error?.response?.data?.errors?.email
                    );
                    signupErrors.value.push(
                        error?.response?.data?.errors?.user_name
                    );
                    hasEmailError.value = signupErrors.value[0];
                    hasUsernameError.value = signupErrors.value[1];
                }
            };

            // ==== PREFILL ====
            // Check query for selected account level
            switch (accountQuery.value.account_level) {
                case 'free':
                    selectedValues.accountLevel = '5';
                    selectedValues.subscriptionLength = '0.3';
                    break;
                case 'basic':
                    selectedValues.accountLevel = '6';
                    selectedValues.subscriptionLength = String(
                        accountQuery.value.subscription_length ?? ''
                    );
                    break;
                case 'basic_plus':
                    selectedValues.accountLevel = '7';
                    selectedValues.subscriptionLength = String(
                        accountQuery.value.subscription_length ?? ''
                    );
                    break;
                case 'professional':
                    selectedValues.accountLevel = '8';
                    selectedValues.subscriptionLength = String(
                        accountQuery.value.subscription_length ?? ''
                    );
                    break;
                case 'professional_plus':
                    selectedValues.accountLevel = '9';
                    selectedValues.subscriptionLength = String(
                        accountQuery.value.subscription_length ?? ''
                    );
                    break;
                default:
                    selectedValues.accountLevel = '';
                    selectedValues.subscriptionLength = String(
                        accountQuery.value.subscription_length ?? ''
                    );
                    break;
            }

            // ==== LIFECYCLE ====
            onMounted(async () => {
                termsLink.value = getMarketingLink('support/docs/terms/');
            });

            // EXPOSE
            return {
                accountQuery,
                options,
                selectedValues,
                hasEmailError,
                hasUsernameError,
                termsLink,
                isAccountLevelEmpty,
                isSubscriptionLengthLocked,
                config,
                isCityInputDisabled,
                isRegionInputVisible,
                isStateInputVisible,
                isProvinceInputVisible,
                onFormSubmit,
                createAccount,
            };
        },
    });
</script>

<style lang="scss">
    .user-access-radio-list {
        li {
            display: inline-block;
        }
    }
</style>
